import Header from "../../globals/header";
import Body from "../../globals/body";
import Root from "../../globals/root";
import React from 'react';
import FormularNew from "./formularNew";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card';

import EyeCatcher from "../../globals/eycatcher";

function Kontakt(){

    return (
        <Root>
            <Header>
      
                <EyeCatcher heading="Nehmen Sie gerne mit uns Kontakt auf!" >
         
                Wir freuen uns, von Ihnen zu hören. Sie können uns über das Kontaktformular auf dieser Website, über unsere Social Media Kanäle, per E-Mail oder telefonisch erreichen. 
                </EyeCatcher>
        
            </Header>
    
            <Body> 
                <Row >

                    <Col md={3}/>

                    <Col>
                   
                        <Card className="mx-3 mb-5" style={{borderColor: "orange"}} >
            
                            <Card.Body>
                                <Card.Title><h4>Unsere Kontaktdaten</h4></Card.Title>
            
                                <Card.Text>
              
                                    <hr/>
                                    <p><b>Telefon</b>: <span style={{float:"right"}}>+497361 460852</span></p>
                                    <p><b>Fax</b>: <span style={{float:"right"}}>+497361 460923</span> </p>
                                    <p><b>E-Mail</b>: <span style={{float:"right"}}>info@solid-bau-aalen.de</span> </p>
                
                                </Card.Text>
            
                            </Card.Body>
                        </Card> 
                    </Col>

                    <Col md={3}/>
                </Row>
      
                <Row>
                    <Col/>
               
                    <Col md={8} xs={10}>
                        
                        <Row>
                            <FormularNew/>
                        </Row>

                    </Col>
                    
                    <Col/>
                  
                </Row>
      
            </Body>
        </Root>
    )
}

export default Kontakt;