import Card from 'react-bootstrap/Card';
import "../../../App.css";
import {Component} from "react";
import {ArrowUpSquareFill, ArrowDownSquareFill} from "react-bootstrap-icons";
import React from 'react';

export default class JobCar extends Component{

    constructor(props) {
        super(props);

        this.state = {clicked: false};

      }

      handleClick(event) {
        
        console.log("Clicked");

        console.log(this.state);


        if(this.state.clicked){
            this.setState({clicked :false});
        }else{
            this.setState({clicked :true});
        }
      }

    render(){

        let arrow =  <ArrowDownSquareFill onClick={this.handleClick.bind(this)}></ArrowDownSquareFill>

        let text  = [<p><b>Standort:</b> {this.props.standort}</p>,<p><b>Beginn:</b> {this.props.begin}</p>]

        if (this.state.clicked) {
            
            arrow = <ArrowUpSquareFill onClick={this.handleClick.bind(this)}></ArrowUpSquareFill>;

            if (this.props.type != "Ausbildung"){
                text = [<p><b>Standort:</b> {this.props.standort}</p>,<p><b>Beginn:</b> {this.props.begin}</p>,<p> <b>Ihr Profil:</b> <ul>{this.props.requirements.map(req => <li style={{color: "orange"}}><span style={{color: "black"}}>{req}</span></li>)}</ul></p>,<p> <b>Aufgaben:</b> <ul>{this.props.tasks.map(req => <li style={{color: "orange"}}><span style={{color: "black"}}>{req}</span></li>)}</ul></p>, <p><b>Wir bieten Ihnen:</b><ul>{this.props.benefits.map(req => <li style={{color: "orange"}}><span style={{color: "black"}}>{req}</span></li>)}</ul></p>] ;
            }else{

                text = [<p><b>Standort:</b> {this.props.standort}</p>,<p><b>Beginn:</b> {this.props.begin}</p>,<p> <b>Ihr Profil:</b> <ul>{this.props.requirements.map(req => <li style={{color: "orange"}}><span style={{color: "black"}}>{req}</span></li>)}</ul></p>,<p><b>Ausbildungsdauer:</b> 3 Jahre</p>,<p>{this.props.description}</p>] ;
            }
          }

        let onClick = undefined;
        if(this.props.mobile){
            onClick= this.handleClick.bind(this);
        }
    
    return(

       
    <Card onClick={onClick} style={this.props.cardStyle} className="mx-3 rounded-top">

        <Card.Body>

            <Card.Title>{this.props.title}</Card.Title>

                <Card.Subtitle>{this.props.type}</Card.Subtitle>

            <Card.Text className="mt-3" >

                {text}
    
                {arrow}
    
            </Card.Text>
       
            <hr/>

            <a href="/kontakt" className="btn text-white submitbutton">Jetzt Bewerben!</a>
        
        </Card.Body>
    </Card>

) 
}
}