import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import CustomNav from "./components/globals/customNav.jsx";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from "./components/views/Home/Home.jsx";
import Leistungen from "./components/views/Leistungen/Leistungen";
import Karriere from "./components/views/Karriere/karriere";
import Kontakt from "./components/views/Kontakt/kontakt";
import Footer from "./components/globals/footer.jsx";
import Impressum from './components/views/Impressum/Impressum.jsx';
import Datenschutz from './components/views/Datenschutz/Datenschutz.jsx';



//import 'bootstrap/dist/js/bootstrap.js';



function App() {


  return (
    <Router basename="/">
      <CustomNav/>
      <Switch>
     <Route path="/home" component={Home} />
     <Route path="/leistungen" component={Leistungen} />
     <Route path="/karriere" component={Karriere} />
     <Route path="/kontakt" component={Kontakt} />
     <Route path="/impressum" component={Impressum} />
     <Route path="/datenschutz" component={Datenschutz} />
     <Route path="/sitemap" component={Kontakt} />
     <Redirect to="/home"/>
     </Switch>

     <Footer/>
    </Router>
  );
}




export default App;
