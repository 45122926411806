import Header from "../../globals/header";
import Body from "../../globals/body";
import Root from "../../globals/root";
import EyeCatcher from "../../globals/eycatcher";
import MediaQuery from 'react-responsive'
import LeistungenDesktop from "./LeistungenDesktop";
import LeistungenMobile from "./LeistungenMobile";
import React from 'react';
function Leistungen(){

    return (
        <Root>
   
            <Header title={"Leistungen"}>

                <EyeCatcher heading="Unsere Leistungen">Mit langjähriger Erfahrung auf unserem Gebiet bieten wir Ihnen Zuverlässigkeit, Pflichtbewusstsein und Know-how, zugeschnitten auf Ihr individuelles Projekt. Trotz unserer jahrzehntelangen Tradition setzen wir auf aktuelle Technik und modernste Arbeitsweisen. Alle Baumaßnahmen der Firma Solid-Bau werden nach den aktuellen anerkannten Regeln der Technik ausgeführt.</EyeCatcher>

            </Header>

            <Body>

                <MediaQuery minWidth={992}>

                    <LeistungenDesktop/>

                </MediaQuery>

                <MediaQuery maxWidth={992}>

                    <LeistungenMobile/>

                </MediaQuery>
                
            </Body>
         
        </Root>
       
    )
}

export default Leistungen;

