import Container from 'react-bootstrap/Container';
import React from 'react';

function Root(props){

    return (

    <Container fluid  className="container-full px-0">

                {props.children}

    </Container>

    )
}

export default Root;