import CardGroup from 'react-bootstrap/CardGroup';
import CardColumns from 'react-bootstrap/CardColumns';
import Container from 'react-bootstrap/Container';
import MediaQuery from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../../../App.css";
import JobCard from './JobCard.jsx';
import React from 'react';

export default function JobBoard(props){

return(
    <Container fluid className="px-1 mx-1 py-3 my-3 bg-light rounded border" >

        <Row className="justify-content-center mx-4 rounded">
            <Col>
                <Row>
                    <h3>{props.heading}</h3>
                </Row>
                <Row>
                    <p>{props.text}</p>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>

                <MediaQuery minWidth={992}>
                    <CardGroup  >

                        {props.jobs.map(job => 

                            <JobCard title={job.title} type={job.type} standort={job.standort} begin={job.begin} requirements={job.requirements} tasks={job.tasks} description={job.description} benefits={job.benefits} cardStyle={{}}/>

    
                            )                       
                        } 
                    </CardGroup>
    
                </MediaQuery>

                <MediaQuery maxWidth={992} >

  
                    <CardColumns >


                        {props.jobs.map(job => 
        
                            <JobCard title={job.title} mobile={true} type={job.type} standort={job.standort} begin={job.begin} requirements={job.requirements} tasks={job.tasks} benefits={job.benefits} cardStyle={{}}/>

                            )               
                        } 


                    </CardColumns>
    
                </MediaQuery>
    
            </Col>
    
        </Row>
    </Container>)

}