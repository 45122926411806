import Carousel from 'react-bootstrap/Carousel';
import baggerOther from "./img/baggerother.jpeg";
import craneArt from "./img/craneArt.jpeg";
import fence from "./img/fence.jpeg";
import fence2 from "./img/fence2.jpeg";
import baggerSnow from "./img/baggerSnow.jpeg";
import baggerOtherMobile from "./img/baggerotherMobile.jpeg";
import craneArtMobile from "./img/craneMobile.jpeg";
import fenceMobile from "./img/fenceMobile.jpeg";
import fence2Mobile from "./img/fence2Mobile.jpeg";
import baggerSnowMobile from "./img/baggerSnowMobile.jpeg";
import mauer from  "./img/mauer.jpg"
import beton from "./img/betonCropped.jpg"
import betonMobile from "./img/betonCroppedMobile.jpg"
import crane from "./img/craneNew.jpg"
import craneMobile from "./img/craneNewMobile.jpg"
import MediaQuery from 'react-responsive';
import { Container } from 'react-bootstrap';
import React from 'react';
export default function SlideShow(){

return(
<Container fluid className="px-0">
  <Carousel>
  <Carousel.Item>
  <MediaQuery minWidth={992}>
    <img
      className="d-block w-100"
      src={crane}
      alt="First slide"
    />
     </MediaQuery>
     <MediaQuery maxWidth={992}>
    <img
      className="d-block w-100"
      src={craneMobile}
      alt="First slide"
    />
     </MediaQuery>
  </Carousel.Item>
  <Carousel.Item>
  <MediaQuery minWidth={992}>
    <img
      className="d-block w-100"
      src={beton}
      alt="First slide"
    />
     </MediaQuery>
     <MediaQuery maxWidth={992}>
    <img
      className="d-block w-100"
      src={betonMobile}
      alt="First slide"
    />
     </MediaQuery>
  </Carousel.Item>

  </Carousel>
  </Container>
  )
}
