import CardColumns from 'react-bootstrap/CardColumns';
import Card from 'react-bootstrap/Card';
import entwurf from "./img/entwurf.jpg"
import sanierung from "./img/sanierung.jpg";
import baugrube from "./img/baugrube.jpeg"
import React from 'react';
function LeistungenMobile(){


return(
<CardColumns>

    <Card  className="mr-2 rounded-top">

        <Card.Img  variant="top" src={entwurf} />

        <Card.Body>

            <Card.Title>Hochbau</Card.Title>

            <Card.Text>
            Jedes Projekt im Hochbau stellt eine individuelle Herausforderung dar. Von Bürogebäuden und Ein- oder Mehrfamilienhäusern bis hin zu modernen Industriegebäuden – wir unterstützen Sie von der Planung bis zur Realisierung Ihres Bauvorhabens und deren Abschluss. Dabei berücksichtigen wir durch unsere ökologische Vorgehens- und Arbeitsweise stets die umwelttechnischen Standards.

            </Card.Text>

        </Card.Body>
    
    </Card> 

    <Card className="mr-2 rounded-top">

        <Card.Img variant="top" src={baugrube}/>
            <Card.Body>
                <Card.Title>Tiefbau</Card.Title>
                <Card.Text>
                Während der Kundenwunsch im Vordergrund steht, ist für uns im Bereich Tiefbau auch eine umweltschonende Arbeitsweise von großer Bedeutung. Durch den Einsatz von modernen Maschinen und qualifizierten Fachkräften ist es uns möglich, beiden Ansprüchen gerecht zu werden. Ob Baugrubenaushub, Erdbewegungen oder Entwässerungsarbeiten – bei uns sind Sie in den richtigen Händen. 
                </Card.Text>
            </Card.Body>
    </Card> 

    <Card className="mr-2 rounded-top">
    
        <Card.Img variant="top" src={sanierung}/>
            
            <Card.Body>
                <Card.Title>Sanierung</Card.Title>
                <Card.Text>
                Gebäudesanierungen gestalten zu einem großen Teil die Zukunft des Bauwesens und tragen maßgeblich zur Nachhaltigkeit bei. Aus diesem Grund sind Umbauarbeiten, Bestandserweiterungen sowie Grundrissveränderungen von Gebäuden ein fester Bestandteil unseres Portfolios, bei dem wir gerne Ihre individuellen Wünsche berücksichtigen. Profitieren Sie bei der Umsetzung Ihres Bauvorhabens von unserer jahrelangen Erfahrung in Planung und Ausführung sowie von unserem qualifizierten Fachpersonal. 
                </Card.Text>
            </Card.Body>
 
    </Card> 
</CardColumns>
)}

export default LeistungenMobile;