import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import "../../App.css";
import logo from "./img/logo.png"
import MediaQuery from 'react-responsive';

export default function CustomNav (props){

  let links = {

    "/home" : {

      classes : {
      
        active : false,
    
      
      }

    },
     "/Hochbau" : {

      classes : {
        
        active : false,
      
      }

     },
    "/Leistungen" : {

      classes : {
  
        active : false,

      }

    },
    "/Karriere" : {

      classes : {

        active : false,
       
      }

    },
    "/Kontakt" : {

      classes : {
       
        active : false,
      
      }

    }

  };

  const location = useLocation();

  if(location.pathname){

  

    if(links[location.pathname]){

        links[location.pathname].classes.active = true;

    }
  }


    return(
      <Container fluid className="px-0">
      <MediaQuery minWidth={992}>
      <Navbar collapseOnSelect onToggle={(e) => console.log(e)} expand="lg" bg="light" variant="light" className="justify-content-center">
      
      <Navbar.Collapse>
      <Navbar.Brand data-toggle="collapse" href="/home"><img src={logo} /></Navbar.Brand>
      </Navbar.Collapse>
      <Navbar.Toggle  aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
  
      <Nav >
        <Nav.Link className={Object.keys(links["/home"].classes).filter(key => links["/home"].classes[key]).join(" ") + " links p-5"} href="/home">Home</Nav.Link>
        <Nav.Link className={Object.keys(links["/Leistungen"].classes).filter(key => links["/Leistungen"].classes[key]).join(" ") + " links p-5" } href="/Leistungen">Leistungen</Nav.Link>
        <Nav.Link className={Object.keys(links["/Karriere"].classes).filter(key => links["/Karriere"].classes[key]).join(" ") + " links p-5" } href="/Karriere">Karriere</Nav.Link>
        <Nav.Link className={Object.keys(links["/Kontakt"].classes).filter(key => links["/Kontakt"].classes[key]).join(" ") + " links p-5 "} href="/Kontakt">Kontakt</Nav.Link>
      </Nav>      
      </Navbar.Collapse>
    </Navbar>
      </MediaQuery>
    
    
    <MediaQuery maxWidth={992} >
    
    <Navbar collapseOnSelect onToggle={(e) => console.log(e)} expand="lg" bg="light" variant="light">
      
      <Navbar.Collapse>
      <Navbar.Brand data-toggle="collapse" href="/home"><img src={logo} /></Navbar.Brand>
      </Navbar.Collapse>
      <Navbar.Toggle  aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
      <Nav >
        <Nav.Link className={Object.keys(links["/home"].classes).filter(key => links["/home"].classes[key]).join(" ") + " p-3"} href="/home">Home</Nav.Link>
        <Nav.Link className={Object.keys(links["/Leistungen"].classes).filter(key => links["/Leistungen"].classes[key]).join(" ") + " p-3"} href="/Leistungen">Leistungen</Nav.Link>
        <Nav.Link className={Object.keys(links["/Karriere"].classes).filter(key => links["/Karriere"].classes[key]).join(" ") + " p-3"} href="/Karriere">Karriere</Nav.Link>
        <Nav.Link className={Object.keys(links["/Kontakt"].classes).filter(key => links["/Kontakt"].classes[key]).join(" ") + " p-3"} href="/Kontakt">Kontakt</Nav.Link>
      </Nav>      
      </Navbar.Collapse>
    </Navbar>
    </MediaQuery>
    
    </Container>

    )
  }


  