import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
export default function EyeCatcher(props){



    return(
       <div className="my-3">
        
            <Row>

                <Col xs={1}/>


                <Col>

                <Row>


                <Col >
                <h2>{props.heading}</h2>
                <p className="lead">
                    {props.children}
                </p>
                </Col>
                

           

                </Row>

                <Row>
                    <Col md={3}>
                        <hr className="mb-0" style={{ "borderColor": "orange"}}/>
                    </Col>
                    <Col>
                    <hr className="mb-0  d-none d-md-block"/>
                   
                    
                    </Col>
                </Row>

   

                

                </Col>

              

                <Col xs={1}/>



            </Row>

        
        </div>

     

    )
}