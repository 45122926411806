import { Container } from "react-bootstrap";
import {ArrowUpSquareFill, ArrowDownSquareFill} from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import Sticky from 'react-sticky-el';
import {Link} from 'react-scroll';
import React from 'react';

export default function Guideelement(props){
     
return(
     <Sticky mode={"bottom"} className="d-flex justify-content-center"   >
          <Link activeClass="active" to="ausbildung" className="btn p-2 m-2 text-white submitbutton stretched-link" spy={true} smooth={true}  duration={500} delay={50}>
               <p><ArrowDownSquareFill /> <b>Ausbildungsplätze:</b> </p>
          </Link>
     </Sticky>)
    
   
}