
import Container from 'react-bootstrap/Container';

import React from 'react';
import MediaQuery from 'react-responsive';
import Content from './Content.jsx';

export default function Overview(){

    const classes = ["mx-3", "rounded-top"];



    return( 
    <Container fluid className="p-3" >
    

    <MediaQuery minWidth={992}>
        <Content classes={["mx-3", "rounded-top", "cardHome"]} />
    </MediaQuery>

    <MediaQuery maxWidth={992}>
        <Content classes={["mx-3", "rounded-top", "cardMobile"]} />
    </MediaQuery>

    </Container>)

}
