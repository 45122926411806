import FileComponent from "./FileComponent";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import React from 'react';
export default function FileOverview(props){
    let files = <Container></Container>

    if(props.files){
        files =  <Row>{props.files.map(file =>  <FileComponent handler={props.handler} id={file.name} key={file.name} filename={file.oldname} />)}</Row>
    }
    return (
        
        <Container >{files}</Container>
       
    )
}