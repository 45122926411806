import Leistung from "./Leistung";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import entwurf from "./img/entwurf.jpg"
import sanierung from "./img/sanierung.jpg";
import baugrube from "./img/baugrube.jpeg"
import React from 'react';
function LeistungenDesktop(){

    return(

        <Col>
                <Row>
                    
                    
                        <Leistung description={"Hochbau"} image={entwurf}  alignment="left">
                        Jedes Projekt im Hochbau stellt eine individuelle Herausforderung dar. Von Bürogebäuden und Ein- oder Mehrfamilienhäusern bis hin zu modernen Industriegebäuden – wir unterstützen Sie von der Planung bis zur Realisierung Ihres Bauvorhabens und deren Abschluss. Dabei berücksichtigen wir durch unsere ökologische Vorgehens- und Arbeitsweise stets die umwelttechnischen Standards.
                        </Leistung>

                </Row>
                <Row>
                        <Col>
                                <hr>
                                </hr>

                        </Col>
                        <Col>
                        </Col>

                </Row>

                <Row>

                        <Leistung description={"Tiefbau"} image={baugrube} alignment="right" isGrey={true}>
                        Während der Kundenwunsch im Vordergrund steht, ist für uns im Bereich Tiefbau auch eine umweltschonende Arbeitsweise von großer Bedeutung. Durch den Einsatz von modernen Maschinen und qualifizierten Fachkräften ist es uns möglich, beiden Ansprüchen gerecht zu werden. Ob Baugrubenaushub, Erdbewegungen oder Entwässerungsarbeiten – bei uns sind Sie in den richtigen Händen.
                        </Leistung>

                </Row>
                <Row>
                        <Col>
                        </Col>
                        <Col>
                                <hr>
                                </hr>
                        </Col>

                </Row>

                <Row>

                        <Leistung description={"Sanierung"} image={sanierung} alignment="left">
                        Gebäudesanierungen gestalten zu einem großen Teil die Zukunft des Bauwesens und tragen maßgeblich zur Nachhaltigkeit bei. Aus diesem Grund sind Umbauarbeiten, Bestandserweiterungen sowie Grundrissveränderungen von Gebäuden ein fester Bestandteil unseres Portfolios, bei dem wir gerne Ihre individuellen Wünsche berücksichtigen. Profitieren Sie bei der Umsetzung Ihres Bauvorhabens von unserer jahrelangen Erfahrung in Planung und Ausführung sowie von unserem qualifizierten Fachpersonal. 
                        </Leistung>


                </Row>
        </Col>)
}

export default LeistungenDesktop;