import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import datenschutz from './datenschutz.png'
export default function Datenschutz(props){

    return(
        <Row>
            <Col /> 
            <Col xs={10}>
            <h3> Datenschutzerklärung:</h3>

    
 
<Row className="my-3">
Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet. Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung. Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.
</Row>
<Row className="my-3">

<p style={{ color: 'orange',fontWeight: 'bold'}}>I. Datenschutzerklärung dieser Webseite</p>
<p>Nachstehende Regelungen informieren Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die Nutzung und die Verarbeitung von personenbezogenen Daten durch den Anbieter Solid-Bau Bauunternehmung GmbH Co.KG, Talweg 129, 73434 Aalen, Tel.: +497361 460852,
E-Mail: info@solid-bau-aalen.de
Wir weisen darauf hin, dass die internetbasierte Datenübertragung Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch Dritte somit unmöglich ist.
</p>

</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</p>

<p>Beim Aufrufen unserer Website www.solid-bau-aalen.de durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:</p>
<p>
• IP-Adresse des anfragenden Rechners

• Datum und Uhrzeit des Zugriffs
• Name und URL der abgerufenen Datei
• Website, von der aus der Zugriff erfolgt (Referrer-URL)
• verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.
</p>

<p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
<p>
• Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
• Gewährleistung einer komfortablen Nutzung unserer Website,
• Auswertung der Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken.
</p>

<p>
Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.

Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter der Ziff. 4 dieser Datenschutzerklärung. Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
</p>
<p>
• Browsertyp und Browserversion
• verwendetes Betriebssystem
• Referrer URL
• Hostname des zugreifenden Rechners
• Uhrzeit der Serveranfrage.
Diese Daten sind nicht bestimmten Personen zuordenbar.Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
</p>
</Row>
<Row  className="my-3">
<p  style={{ color: 'orange',fontWeight: 'bold'}}>  
3. Weitergabe von Daten
</p>
<p>
Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
</p>
<p>
• Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
• die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
• für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
• dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
4. Betroffenenrechte
</p>
<p>
Sie haben das Recht:
• gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
• gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
• gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
• gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
• gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;
• gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
• gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Sitzes wenden.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
5. Widerspruchsrecht
</p>
<p>
Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an info@solid-bau-aalen.de.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
6. Datensicherheit
</p>
<p>
Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
</p>

</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
7. Kontaktmöglichkeit
</p>
<p>
Wir bieten Ihnen auf unserer Seite die Möglichkeit, mit uns per E-Mail in Verbindung zu treten. In diesem Fall werden die vom Nutzer gemachte Angaben zum Zwecke der Bearbeitung seiner/ihrer Kontaktaufnahme gespeichert. Eine Weitergabe an Dritte erfolgt dabei nicht. Ein Abgleich der so erhobenen Daten mit Daten, die möglicherweise durch andere Komponenten unserer Seite erhoben werden, erfolgt ebenfalls nicht.,
</p>
</Row>
<Row  className="my-3">
<p  style={{ color: 'orange',fontWeight: 'bold'}}>II. Datenschutz im Auftragsverhältnis</p>
Wir erheben, verarbeiten und nutzen personenbezogene Daten im Rahmen eines Auftragsverhältnisses nur, soweit die EU-Datenschutz-Grundverordnung (DSGVO) bzw. das BDSG oder eine andere Rechtsvorschrift dies erlaubt oder anordnet oder Sie eingewilligt haben.
</Row>
<Row  className="my-3">
<p  style={{ color: 'orange',fontWeight: 'bold'}}>
1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
</p>
<p>
Verantwortlicher: 	Solid-Bau GmbH &. Co. KG
			73434 Aalen, Talweg 129, Deutschland
E-Mail:		www.solid-bau-aalen.de 
Telefon:		07361/460852
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck und deren Verwendung
</p>
<p>
Wenn Sie uns beauftragen, erheben wir folgende Informationen:
• Anrede, Vorname, Nachname,
• eine gültige E-Mail-Adresse,
• Anschrift,
• Telefonnummer (Festnetz und/oder Mobilfunk)
• Informationen, die für die Durchführung des Auftrags notwendig sind.
</p>
<p>
Die Erhebung dieser Daten erfolgt,
• um Sie als unseren Kunden identifizieren zu können;
• um Sie angemessen beraten zu können;
• zur Korrespondenz mit Ihnen;
• zur Rechnungsstellung;
• zur Abwicklung von evtl. vorliegenden Haftungsansprüchen sowie der Geltendmachung etwaiger Ansprüche gegen Sie.

Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b DSGVO zu den genannten Zwecken für die angemessene Bearbeitung des Auftrags und für die beidseitige Erfüllung von Verpflichtungen aus dem Vertrag erforderlich.
Die für die Beauftragung von uns erhobenen personenbezogenen Daten werden nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO bis zum Ablauf der gesetzlichen Aufbewahrungspflichten (aus HGB, StGB oder AO) gespeichert und danach gelöscht, falls Sie nicht in eine darüberhinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>

3. Weitergabe von Daten an Dritte
</p>
<p>
Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:

• Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
• die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
• für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht, sowie
• dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
4. Betroffenenrechte
</p>
<p>
Sie haben das Recht:
• gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;
• gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
• gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
• gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
• gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
• gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und
• gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Sitzes wenden.
</p>
</Row>
<Row  className="my-3">
<p style={{ color: 'orange',fontWeight: 'bold'}}>
III. Aktualität und Änderung dieser Datenschutzerklärung
</p>
<p>
Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 26.03.2023
Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website www.solid-bau-aalen.de der Rubrik „Datenschutz“ von Ihnen abgerufen und ausgedruckt werden.
</p>
</Row>


        
       
        </Col>
        <Col/>
        </Row>

    )
}