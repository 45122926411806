import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import "../../../App.css";
import React from 'react';

export default function Content(props){

    const classes = props.classes;




    return( 
 
<Container fluid >
    <CardGroup >
 
        <Card  className={classes} >
        
            <Card.Body>
     
                <Card.Title><h4>Mit uns bauen</h4></Card.Title>
                    <Card.Text>
                    Unser Portfolio für Sie im Überblick.
                    </Card.Text>
                    <hr/>
                    <div className="d-flex justify-content-center"> 
                    <a href="/leistungen" className="btn text-white submitbutton stretched-link">Zu unserem Portfolio</a>
                    </div>
                </Card.Body>
            </Card> 
     
      


        <Card  className={classes}>
            
            <Card.Body>
        
                <Card.Title><h4>Karriere</h4></Card.Title>
                    <Card.Text>
                    Unsere aktuellen Stellen im Überblick
                    </Card.Text>
                    <hr/>
                    <div className="d-flex justify-content-center"> 
                    <a href="/karriere" className="btn text-white submitbutton stretched-link">Zu unseren offenen Stellen</a>
                    </div>
                </Card.Body>
            </Card> 
       

   
        <Card  className={classes} >
            
            <Card.Body>
   
                <Card.Title><h4>Kontakt</h4></Card.Title>
                    <Card.Text>
                    Wir sind immer für Sie erreichbar
                       
                    </Card.Text>
                    <hr/>
                    <div className="d-flex justify-content-center">
                    <a href="/kontakt" className="btn text-white submitbutton stretched-link">Zum Kontaktformular</a>
                    </div>
                </Card.Body>
            </Card> 
            </CardGroup>
            </Container>)

}
