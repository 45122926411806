import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MediaQuery from 'react-responsive';
import React from 'react';

function Body(props){

    return (

            <Container fluid className="container-full">
           
                <Row>

                    <Col md={1}/>
                        
                        <Col  md={10}>{props.children}</Col>
                    
                    <Col md={1}/>

                </Row>
          

            </Container>
      
    )
}

export default Body;