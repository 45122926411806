import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import React from 'react';
function Leistung(props){
    

    if(props.alignment == "right"){

            return renderRightAlignment(props);

    }else{

        return renderLeftAlignment(props);
    }
}

function renderRightAlignment(props){



    return (
        <Container fluid>
            <Row>
                <Col>  
                    <Row>

                        <Col md={8}>
                            <Row >
                                <h3>{props.description}</h3>
    
                            </Row>
                            <p style={{fontSize: "20px"}}>{props.children}</p>
                        </Col>
    
                        <Col>

                            <Image className="mx-0" src={props.image} fluid={true} rounded />
            
                        </Col>

                    </Row>

                </Col>
       
            </Row>


        </Container>
    
       
    )



}

function renderLeftAlignment(props){



    return (

        <Container fluid>

            <Row >
                
                <Col>
              
                    <Row className="flex-column-reverse flex-md-row">

                        <Col>

                            <Image className="mx-0" src={props.image} fluid={true} rounded />

                        </Col>

                        <Col md={8}>

                            <Row>
                        
                                <h3>{props.description}</h3>
    
                            </Row>

                            <Row>
                        
                                <p style={{fontSize: "20px"}}>{props.children}</p>

                            </Row>

                        </Col>

                    </Row>

                </Col>
           
            </Row>

        </Container>
       
    )



}

export default Leistung;