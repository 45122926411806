import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../App.css";
import { Linkedin, Instagram, Phone, Envelope } from 'react-bootstrap-icons';
import React from 'react';

export default function IconBar(){
    return(
        <Row>

            <Col xs={3}>
            <a href="https://www.linkedin.com/company/solid-bau-aalen/"  style={{color: "black"}}>
                <Linkedin className="symbol"/>
                </a>
            </Col>

            <Col xs={3}>
                <a href="https://www.instagram.com/solid_bau/"  style={{color: "black"}}><Instagram className="symbol"/></a>
            </Col>


          <Col xs={3}>
          <a href="tel:+497361460852"  style={{color: "black"}}><Phone className="symbol"/></a>
           </Col>


            <Col xs={3}>
                <a href="mailto:info@solid-bau-aalen.de"  style={{color: "black"}}><Envelope className="symbol"/></a>
            </Col>

        

        </Row>
    )
}