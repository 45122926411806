import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import React from 'react';


export default function Impressum(){

    return(
        <Row>
            <Col/>
            <Col xs={10}>


         <h2>Impressum</h2>

            <Container fluid className="my-5">
            <Col/>
            <Col>
            <Row>Angaben gemäß § 5 TMG:</Row>
            <Row>Solid-Bau Bauunternehmung GmbH Co.KG</Row>
            <Row>Talweg 129</Row>
            <Row>73434 Aalen</Row>
            <Row>E-Mail: info@solid-bau-aalen.de</Row>
            <Row>Registergericht: Amtsgericht Ulm</Row>
            <Row>Registernummer: HRA723753</Row>
            <Row>USt-IdNr./VAT Nr. gemäß § 27 a Umsatzsteuergesetz: DE 50492/29412</Row>
            <Row>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Ronald Kruttschnitt</Row>
            <Row>Geschäftsführer: Ronald Kruttschnitt</Row>

            <Row>Haftungsausschluss (Disclaimer)</Row> 
            <Row>Haftungshinweis: </Row>
            <Row>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</Row>
<Row>Urheberrecht:</Row>
<Row>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. </Row>
</Col>
<Col/>
</Container>





        </Col>
        <Col/>
        </Row>
    )
}