import Container from 'react-bootstrap/Container';
import React from 'react';

function Header(props){

    return (

            <Container fluid className="container-full mb-5">

                {props.children}

            </Container>
  
    )
}

export default Header;