import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/esm/Button";
import {Trash} from "react-bootstrap-icons";
import React from 'react';
export default function FileComponent(props){


    let name = props.filename;

    if(name.length > 10){
        name = name.slice(0, 10)
    }
    return (
        <Col md={3} xs={6} className="bg-light rounded border px-3 mx-3 my-1">
            <Row>
                <Col className="p-1">
                    {name}
                </Col>
                <Col className="d-flex p-0 justify-content-end">
                    <Button  className="p-0 m-0" onClick={(e)=>props.handler(e, props.id)}  variant="danger"><Trash size={15}/></Button>
                </Col>
            </Row>
        </Col>
    )
}