import Header from "../../globals/header";
import Body from "../../globals/body";
import Root from "../../globals/root";
import React from 'react';

import SlideShow from "./slideShow";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Overview from "./Overview";

function Home(){

    return (
        <Root>
       
            <Header> <Row><SlideShow/></Row></Header>
            <Body>
                <Row>
                    <Col>
                    </Col>

                    <Col xs={10}>
                
                        <p className="display-4">Solid-Bau – Sie bauen auf uns. Wir bauen für Sie!</p>

                        <p className="lead">
                        Als familiengeführtes Unternehmen in der zweiten Generation sind wir stolz, dass wir auf eine mittlerweile 20-jährige Geschichte im Bauwesen zurückblicken können. Um unseren Kunden maßgeschneiderte Lösungen bieten zu können, investieren wir kontinuierlich in unser Personal, dessen Weiterbildung, den Nachwuchs und die firmeneigenen Anlagen. Somit möchten wir unseren Teil zur Verbesserung der Lebensqualität im ostwürttembergischen Raum beitragen. Unsere Unternehmenskultur verspricht ein teamorientiertes Arbeiten, wovon wir als Unternehmen, aber auch unsere Kunden durch termingerechte Realisierung von Bauvorhaben profitieren.



                        </p>
         
                    </Col>
                    <Col>
                    </Col>
                </Row>

                <Row>
                <Col sm={3}>
                        <hr style={{ "borderColor": "orange"}}/>
                    </Col>
                    <Col>
                    <hr className="d-none d-md-block"/>
                    </Col>
                </Row>
           
                
                <Row className="mb-3">

                    <Col md={1}/>

                    <Col>
            

                    <Overview />

                    </Col>

                    <Col md={1}/>
                  
                </Row>

            </Body>
            
        </Root>
    )
}

export default Home;