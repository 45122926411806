import Header from "../../globals/header";
import Body from "../../globals/body";
import Root from "../../globals/root";
import {Element} from 'react-scroll';
import JobBoard from './JobBoard';
import MediaQuery from 'react-responsive';
import EyeCatcher from "../../globals/eycatcher";
import Guideelement from "./guideElement";
import React from 'react';

function Karriere(){


    const jobs = [
    {
    title : "Polier/Vorarbeiter (M/W/D)", 
    type: "Festanstellung", 
    standort : "Aalen", 
    begin : "Ab Sofort", 
    requirements : [
        "eine abgeschlossene Ausbildung als Polier, Meister oder Vergleichbares",
        "mehrjährige Berufserfahrung im Hochbau (aber auch Berufsanfänger willkommen)",
	    "Zuverlässigkeit, Selbstständigkeit und Teamfähigkeit",
	    "Führerschein der Klasse B"], 
    tasks :[
        "Mitarbeiterführung",
	    "selbstständiges Durchführen der Bauvorhaben",
	    "Koordination und Disposition von Materiallieferungen",
	    "Mitarbeit auf der Baustelle",
	    "Führen von Kundengesprächen"], 
    benefits : [
        "Job-Bike",
	    "Unbefristetes Arbeitsverhältnis",
	    "Kompetitives Gehalt",
	    "Flache Hierarchien in einem familiengeführten Unternehmen",
	    "Abwechslungsreiche Baumaßnahmen",
	    "Baustellen in der Umgebung (Umkreis: <30km)",
	    "Weiterbildungsmaßnahmen",
	    "Firmenfahrzeug inkl. Ausrüstung",
	    "Firmenevents, (z.B. Weihnachtsfeier)",
    "Vermögenswirksame Leistungen (VWL)",
"Ein kollegiales, freundliches und wertschätzendes Team",
"13. Monatsgehalt"
]},
    { title : "Maurer (M/W/D)", type : "Festanstellung", standort : "Aalen", begin : "Ab Sofort", 
    requirements : [
        "eine abgeschlossene Ausbildung als Maurer oder Vergleichbares ",
	    "mehrjährige Berufserfahrung im Hochbau  (aber auch Berufsanfänger willkommen)",
	    "Zuverlässigkeit, Selbstständigkeit und Teamfähigkeit",
	    "Führerschein der Klasse B"
    ],  
    tasks: [
            "Erstellen von Mauerwerk",
            "Führen von Baumaschinen",
            "Errichten von Außenanlagen",
            "Durchführen von Sanierungsmaßnahmen"],
    benefits : [
            "Job-Bike",
            "Unbefristetes Arbeitsverhältnis",
	        "Kompetitives Gehalt",
	        "Flache Hierarchien in einem familiengeführten Unternehmen",
	        "Abwechslungsreiche Baumaßnahmen",
	        "Baustellen in der Umgebung (Umkreis: <30km)",
	        "Weiterbildungsmaßnahmen",
	        "Firmenevents (z.B. Weihnachtsfeier)",
	        "Vermögenswirksame Leistungen (VWL)",
	        "Ein kollegiales, freundliches und wertschätzendes Team",
            "13. Monatsgehalt"
            ]},
            { title : "Stahl- und Betonbauer (M/W/D)", type : "Festanstellung", standort : "Aalen", begin : "Ab sofort", 
            requirements : ["eine abgeschlossene Ausbildung als Stahl- und Betonbauer oder Vergleichbares",
                "mehrjährige Berufserfahrung im Hochbau (aber auch Berufsanfänger willkommen)",
                "Zuverlässigkeit, Selbstständigkeit und Teamfähigkeit",
                "Führerschein der Klasse B"
            ]
            , tasks: [
                "Schalungs- und Betonbauarbeiten",
                "Bewehrungsarbeiten",
                "Führen von Baumaschinen",
                "Errichten von Außenanlagen",
                "Durchführen von Sanierungsarbeiten"],

            benefits :[
                "Job-Bike",
                "Unbefristetes Arbeitsverhältnis",
                "Kompetitives Gehalt",
                "Flache Hierarchien in einem familiengeführten Unternehmen",
                "Abwechslungsreiche Baumaßnahmen",
                "Baustellen in der Umgebung (Umkreis: <30km)",
                "Weiterbildungsmaßnahmen",
                "Firmenevents (z.B. Weihnachtsfeier)",
                "Vermögenswirksame Leistungen (VWL)",
                "Ein kollegiales, freundliches und wertschätzendes Team",
                "13. Monatsgehalt"
                ] 
    }];

    const ausbildung = [
        { 
        title : "Maurer (MWD)", 
        type : "Ausbildung", 
        standort : "Aalen", 
        begin : "Ab Sofort",  
        requirements : ["allgemeinbildender Schulabschluss", "Motivation", "Begeisterung für das Bauwesen"], 
        description : "Als Maurer/-in bist du sowohl bei der Sanierung als auch beim Neubau und dem Errichten von Außenanlagen beteiligt. Deine Tätigkeiten erstrecken sich über mehrere Fachbereiche, was dich zu einem Allrounder macht. In der Berufsschule erlernst du das Anfertigen von Konstruktionszeichnungen, bauphysikalische und baukonstruktive Grundlagen sowie das Mauern in der überbetrieblichen Ausbildung. Deine Karrierechancen sind in diesem Bereich sehr gut. Durch Weiterbildung und die Berufserfahrung, die du über die Jahre sammelst, kannst du beispielsweise als Vorarbeiter/-in oder Polier/-in arbeiten. Haben wir Dein Interesse geweckt? Dann freuen wir uns auf Deine Bewerbung!"
        },
        { 
        title : "Stahl- und Betonbauer (MWD)", 
        type : "Ausbildung", 
        standort : "Aalen", 
        begin : "Ab sofort",  
        requirements : ["allgemeinbildender Schulabschluss", "Motivation", "Begeisterung für das Bauwesen"],
        description: "Als Stahlbetonbauer/-in erstellst du Schalungen, montierst Stützgerüste, biegst Stahlbewehrungen und baust diese ein. Das Einbauen, Verdichten und Nachbehandeln des Betons ist ebenfalls Teil deines Aufgabenbereichs. Bei uns im Unternehmen erlernst du die praktischen Fähigkeiten, die du für diesen Beruf benötigst. Das Erlernte wendest du innerhalb mehrerer Fachbereiche an, was dich zu einem Allrounder macht. In der Berufsschule erlernst du das Anfertigen von Konstruktionszeichnungen, bauphysikalische und baukonstruktive Grundlagen sowie das Mauern in der überbetrieblichen Ausbildung.  Deine Karrierechancen sind in diesem Bereich sehr gut. Durch Weiterbildung und die Berufserfahrung, die du über die Jahre sammelst, kannst du beispielsweise als Vorarbeiter/-in oder Polier/-in arbeiten. Haben wir dein Interesse geweckt? Dann freuen wir uns auf deine Bewerbung!"}
    ];

    return (

        <Root>
            <Header >
                <EyeCatcher heading="Mit uns durchstarten" >Eine Karriere bei Solid-Bau bringt eine Vielzahl an Chancen mit sich. Wir streben stets Wachstum an, ohne dabei unsere familiären Werte zu vernachlässigen. Werden Sie Teil unseres Teams und wachsen Sie mit uns. Profitieren Sie von einer freundlichen Arbeitsatmosphäre, ausgezeichneter Teamarbeit sowie unserer modernen Unternehmenskultur.
                </EyeCatcher>
                
            </Header>

            <Body >
  
                <JobBoard heading="Gesucht ab sofort:" text="Bei uns sind alle willkommen. Wir sind kontinuierlich daran interessiert, unser Personal zu verstärken, zu fördern und weiterzubilden. " jobs={jobs}/>
                    <MediaQuery maxWidth={992}>

                        <Guideelement/>

                    </MediaQuery>

                    <Element name="ausbildung">

                        <JobBoard id="ausbildung" heading="Wir bilden aus:" text="Für eine Ausbildung bei Solidbau sprechen viele Gründe. Werde Teil von etwas großem!" jobs={ausbildung}/>
                    
                    </Element>
            </Body>



            
        </Root>

   
    )
}

export default Karriere;