import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MediaQuery from 'react-responsive';
import "../../../App.css";
import {Component} from "react";
import axios from 'axios';
import path from 'path';
import _ from "lodash";
import FileOverview from "./fileOverview";
import {v4} from "uuid";
import swal from 'sweetalert';
import React from 'react';
//import { chownSync } from 'fs';

export default class Formular extends Component{

    constructor(props) {
        super(props);
        this.state = {

          files : [] ,

          checked : false
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
      }

    // make upload to uploads 
    async makeUpload(form){
      await axios.post("https://solid-bau-aalen.de:8000/uploads", form, {     
        headers: { 'content-type': 'multipart/form-data' }
      } ).then(function(response){

        console.log("Response" + response);
  
       })
      .catch(function (error) {
        console.log("Upload failed");
        console.log(error);
      });

    }

    handleChange(event, field) {

        let previousState = this.state;

        previousState[field] = event.target.value;

        this.setState(previousState);
      }

    handleCheckChange(event){

      let previousState = this.state;

      previousState["checked"] = !previousState["checked"];

      this.setState(previousState);
    }

    handleUpload(event, field) {


        console.log("uploaded file of type " + event.target.files[0].type);

        //let form =  new FormData();

      let fileName = v4() + path.extname(event.target.files[0].name);


        let newFile = {"name" : fileName, "oldname" : event.target.files[0].name, "file":event.target.files[0] };

        this.setState({
          files:[...this.state.files, newFile]
        });

    

      }

    handleRemoveFile(event, fileName){

      console.log("Should Remove " + fileName);

      let files = [...this.state.files]

      let index = files.findIndex(x => x.name === fileName);

      files.splice(index, 1);

      this.setState({
        files: files
      })


    }
    
    handleSubmit(event) {

      if(this.state["checked"]){

        console.log("checked");

      let form =  new FormData();

      for(let index in this.state.files){

        console.log("Name : " +  this.state.files[index].name);      
     
        form.append("file",this.state.files[index].file, this.state.files[index].name);

      }

      this.makeUpload(form);

      let mailData = {Vorname :this.state["Vorname"], Nachname : this.state["Nachname"], Email :this.state.Email,Text: this.state.Text,
      FileNames : this.state.files.map(file => file.name)}

      axios.post("https://solid-bau-aalen.de:8000/sendmail", mailData)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

      swal("Ihre Email wurde gesendet!");
        
      const formular = document.getElementById('form');
      formular.reset();
        event.preventDefault();
      }else{
        swal("Sie müssen den Datenschutzrichtlinien zustimmen!");
        console.log("not checked");
       // swal("Sie müssen den Datenschutzrichtlinien zustimmen!");
      }
    }
    

    render(){

    return(
    
      <Container fluid className="px-3 mx-1 py-3 my-1 rounded border bg-light rounded border" >

        <h4>Kontaktformular</h4>

        <hr/>

        <Form  onSubmit={this.handleSubmit} id="form">

          <MediaQuery minWidth={992}>

            <Form.Group controlId="Name">

              <Form.Label>Geben Sie bitte Ihren Vor- und Nachnamen an</Form.Label>

                <Row>

                  <Col>

                    <Form.Control type="text" placeholder="Vorname" onChange={(e) =>this.handleChange(e, "Vorname")} />

                  </Col>

                  <Col>

                    <Form.Control type="text" placeholder="Nachname"  onChange={(e)=>this.handleChange(e, "Nachname")} />

                  </Col>

                </Row>       

            </Form.Group>

          </MediaQuery>

          <MediaQuery maxWidth={992}>

            <Form.Group controlId="Name">

              <Form.Label>Geben Sie bitte Ihren Vor- und Nachnamen an</Form.Label>

              <Form.Control type="text" placeholder="Vorname" onChange={(e) =>this.handleChange(e, "Vorname")} />

              <Form.Control className="mt-4" type="text" placeholder="Nachname"  onChange={(e)=>this.handleChange(e, "Nachname")} />

            </Form.Group>

          </MediaQuery>

          <Form.Group controlId="formBasicEmail" className="py-2">

            <Form.Label>E-Mail-Adresse</Form.Label >

            <Form.Control type="email" placeholder="Geben Sie Ihre E-Mail ein"   onChange={(e) =>this.handleChange(e, "Email")}/>

          </Form.Group>

          <Form.Group controlId="message" className="py-2">

            <Form.Label>Was wollen Sie uns mitteilen?</Form.Label>

            <Form.Control size="lg"  as="textarea" placeholder="Geben Sie ihre Nachricht ein"  onChange={(e) =>this.handleChange(e, "Text")}/>

          </Form.Group>

          <Form.Group controlId="formFileMultiple" className="py-3">

            <Form.Label>Laden Sie hier, falls erforderlich, Ihre Anhänge hoch</Form.Label>

            <FileOverview handler={this.handleRemoveFile}files={this.state.files}/>

            <Form.Control type="file" className="pt-3" onChange={(e)=>this.handleUpload(e, "files") } multiple />

          </Form.Group>

          <Form.Group controlId="formBasicCheckbox" >

            <Form.Check type="checkbox" label="Ich habe die Datenschutzerklärung gelesen. Mit der Datenspeicherung und Verarbeitung bin ich einverstanden." onChange={(e)=>this.handleCheckChange(e)} />

          </Form.Group>
        
          <Button className="submitbutton" type="submit" value="Submit"  variant="warning">
            Submit
          </Button>
          
        </Form>
        
      </Container>)
    }

    
    }
