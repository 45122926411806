import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import IconBar from './iconbar';
import 'bootstrap/dist/css/bootstrap.css';
import Row from 'react-bootstrap/Row';

export default function Footer() {
   return (
    <Container fluid>
      <Row>

        <Col md={5} xs={2}/>

          <Col className="justify-content-center my-3">

            <IconBar/>

          </Col>

        <Col md={5} xs={2}/>

      </Row>
    
      <Row className="justify-content-center bg-dark">

        <Nav className="justify-content-center p-3" >
      
          <Nav.Link className=" text-light" href="/impressum">Impressum</Nav.Link>
        
          <Nav.Link className="text-light" href="/datenschutz">Datenschutz</Nav.Link>
    
          <Nav.Link className="text-light" href="/sitemap">Sitemap</Nav.Link>
     
        </Nav>
      </Row>
        
    </Container>
    );
  }


  